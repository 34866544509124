<template>
  <div class="admin-cars-listing">
    <title-bar addBtn :addUrl="getAddCarURL()" title="Cars" />
    <vue-good-table
      class="custom-table-style possible-vertical-align"
      mode="remote"
      :totalRows="pages"
      :isLoading.sync="isLoading"
      :columns="columns"
      :rows="cars"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
        setCurrentPage: currentPage,
      }"
      @on-page-change="onPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <span class="buttons" v-if="props.column.field == 'id'">
          <div class="just-buttons">
            <edit-btn
              :path="getEditCarURL(props.formattedRow[props.column.field])"
            />
            <delete-btn @pressDelete="deleteCar(props.row['@id'])" />
          </div>
        </span>

        <span
          :class="props.column.field"
          v-else-if="props.column.field == 'courier-name'"
        >
          <div v-if="props.row.courier" class="name">
            <div v-if="props.row.courier.fileUrl" class="courier-photo">
              <img
                :src="`${props.row.courier.fileUrl}`"
                :alt="`${props.row.courier.givenName} ${props.row.courier.familyName}`"
              />
            </div>
            <span>{{ `${props.row.courier.givenName}` }}</span>
          </div>
        </span>

        <span :class="props.column.field" v-else-if="props.column.field == 'size'">
          <span>{{ $helper.getEnumTranslation('car_size', props.formattedRow[props.column.field]) }}</span>
        </span>

        <span :class="props.column.field" v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template slot="pagination-bottom" slot-scope="props">
        <table-pagination
          v-model="currentPage"
          :total="pages"
          :per-page="perPage"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import TitleBar from '@/components/TitleBar.vue'
import TablePagination from '@/components/Table/TablePagination.vue'
import EditBtn from '@/components/Buttons/EditBtn.vue'
import DeleteBtn from '@/components/Buttons/DeleteBtn.vue'
import Toast from '@/components/Toast.vue'
import ListUtils from '@/mixins/ListUtils'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  components: {
    TitleBar,
    VueGoodTable,
    TablePagination,
    EditBtn,
    DeleteBtn,
  },
  mixins: [ListUtils],
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      isLoading: false,
      perPage: 15,
      currentPage: 1,
      pages: 0,
      cars: null,
      couriers: [],

      columns: [
        {
          label: 'Car',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Courier',
          field: 'courier-name',
          sortable: false,
        },
        {
          label: 'Size',
          field: 'size',
          sortable: false,
        },
        {
          field: 'id',
          sortable: false,
          width: '100%',
        },
      ],
    }
  },
  methods: {
    loadCars() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
      }
      this.$Cars.getCollection({ params }, 'car_list').then(response => {
        this.pages = response.data['hydra:totalItems']
        this.cars = response.data['hydra:member']
      })
    },
    deleteCar(url) {
      this.$Cars
        .deleteResourceByUrl({ url })
        .then(response => {
          if (response.status === 204) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Car deleted',
              '',
              'success',
            )
            this.loadCars()
          }
        })
        .catch(error => {
          if (error.response) {
            this.$emit('clearAction')
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Failed to delete',
              '',
              'danger',
            )
          }
        })
    },
    onPageChange() {
      this.loadCars()
    },
    getAddCarURL() {
      return `/add-car`
    },
    getEditCarURL(id) {
      return `/cars/${id}/edit`
    },
  },
}
</script>
